<template>
  <div>
    <PageHeader :items="items" :optionalItems="optionalItems">
      <template #filters>
        <div class="search-filters">
          <div class="page-header-search">
            <input
              autocomplete="off"
              type="text"
              name="search"
              :placeholder="$t('Search…')"
              v-model="form.search"
            />
            <button class="" type="button" @click="form.search = ''">
              {{ $t("Reset") }}
            </button>
          </div>
        </div>
      </template>
      <template #buttons>
        <button
          @click="downloadCsv()"
          class="btn btn-primary d-flex align-items-center gap-1"
        >
          <feather-icon class="stroke-icon" :icon="'DownloadIcon'" size="12" />
          <span>{{ $t("Download Template For Import") }}</span>
        </button>
        <button
          @click="importCsv()"
          class="btn btn-primary d-flex align-items-center gap-1"
        >
          <feather-icon class="stroke-icon" :icon="'UploadIcon'" size="12" />
          <span>{{ $t("Import CSV") }}</span>
        </button>
        <button
          @click="$refs.fileInput.click()"
          class="btn btn-primary d-flex align-items-center gap-1"
        >
          <span>{{ file ? file.name : $t("Select File") }}</span>
        </button>
        <input
          style="display: none"
          type="file"
          @change="handleFileUpload"
          accept=".csv"
          ref="fileInput"
        />
      </template>
    </PageHeader>
    <div class="custom-search d-flex justify-content-between mb-1">
      <b-form-group>
        <div class="d-flex align-items-center">
          <span class="text-nowrap"> {{ $t("Rows per page") }} </span>
          <b-form-select
            v-model="pageLength"
            :options="['25', '50', '100']"
            class="ml-1"
            @input="(value) => onPerPageChange({ pageLength: value })"
          />
        </div>
      </b-form-group>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="dextop-table-view">
      <div class="table-responsive api-keys-table">
        <!-- table -->
        <vue-good-table
          styleClass="vgt-table striped"
          :columns="columns"
          :fixed-header="false"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
            trigger: 'enter',
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-per-page-change="onPerPageChange"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Name -->

            <span
              class="d-flex align-items-center justify-content-center gap-2"
              v-if="props.column.field === 'action'"
            >
              <div
                class="cursor-pointer"
                v-if="$can(`${$route.meta.permission}.edit`)"
                @click="$router.push(`/customers/${props.row.id}/edit`)"
              >
                <feather-icon size="16" icon="Edit2Icon" />
              </div>
              <div
                v-if="$can(`${$route.meta.permission}.delete`)"
                class="cursor-pointer"
                @click="destroy(props.row.id)"
              >
                <feather-icon size="16" icon="TrashIcon" />
              </div>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-center flex-wrap">
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="totalRecords"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => onPageChange({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!--==================================-->
    <!--==================================-->
    <div class="reponsive-view-cards">
      <div class="row">
        <div class="col-md-4" v-for="row in rows" :key="row.id">
          <div class="card mb-1">
            <div
              class="card-header d-flex align-items-center justify-content-between"
            >
              <h5 class="card-title">{{ row.companyNumber }}</h5>
              <div class="d-flex gap-3">
                <div
                  class="cursor-pointer"
                  v-if="$can(`${$route.meta.permission}.edit`)"
                >
                  <feather-icon
                    size="18"
                    icon="Edit2Icon"
                    @click="$router.push(`/customers/${row.id}/edit`)"
                  />
                </div>
                <div
                  class="cursor-pointer"
                  v-if="$can(`${$route.meta.permission}.delete`)"
                >
                  <feather-icon
                    size="18"
                    icon="TrashIcon"
                    @click="destroy(row.id)"
                  />
                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <ul class="card-list">
                <li class="align-items-start">
                  <div class="left pr-1">
                    <strong>{{ $t("Company Name") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{ row.companyName }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("VAT") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{ row.vatId }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("City") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{ row.city }}</span>
                  </div>
                </li>
                <li>
                  <div class="left">
                    <strong>{{ $t("Country") }}: </strong>
                  </div>
                  <div class="right">
                    <span>{{ row.country }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!-- Pagination at the bottom -->
      <div class="d-flex justify-content-center mt-2">
        <b-pagination
          :value="1"
          :total-rows="totalRecords"
          :per-page="pageLength"
          first-number
          last-number
          align="right"
          prev-class="prev-item"
          next-class="next-item"
          class="mt-1 mb-0"
          @input="(value) => onPageChange({ currentPage: value })"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
    <!--====================================-->
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import NotificationService from "@/services/notification.service";
import { debounce } from "@/utils/debounce";
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";

export default {
  components: {
    PageHeader,
    Multiselect,
    NotificationService,
  },
  computed: {
    optionalItems() {
      return {
        createBtn1: {
          show: true,
          text: this.$t("Create Customer"),
          icon: "PlusIcon",
          path: "/customers/create",
          permission: "company.create",
        },
      };
    },
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Customers"),
          active: true,
        },
      ];
    },
    columns() {
      return [
        {
          tdClass: "vgt-left-align",
          thClass: "vgt-left-align",
          label: this.$t("Company Number"),
          field: "companyNumber",
        },
        {
          tdClass: "vgt-left-align",
          thClass: "vgt-left-align",
          label: this.$t("Company Name"),
          field: "companyName",
        },
        {
          tdClass: "vgt-left-align",
          thClass: "vgt-left-align",
          label: this.$t("VAT"),
          field: "vatId",
        },
        {
          tdClass: "vgt-left-align",
          thClass: "vgt-left-align",
          label: this.$t("City"),
          field: "city",
        },
        {
          tdClass: "vgt-left-align",
          thClass: "vgt-left-align",
          label: this.$t("Country"),
          field: "country",
        },
        {
          tdClass: "vgt-center-align",
          thClass: "vgt-center-align",
          label: this.$t("Action"),
          field: "action",
          sortable: false,
        },
      ];
    },
  },
  data() {
    return {
      file: null,
      totalRecords: 0,
      pageLength: 25,
      page: 1,
      form: {
        search: "",
        sortBy: "createdAt",
        sortOrder: "desc",
      },
      rows: [],
      searchTerm: "",
    };
  },

  async created() {
    this.debouncedFetch = debounce(async (newValue, oldValue) => {
      try {
        await this.loadItems();
      } catch (e) {
        console.error(e);
      }
    }, 300);
    this.loadItems();
  },
  watch: {
    "form.search"(...val) {
      this.debouncedFetch();
    },
  },

  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this, newProps);
    },

    async importCsv() {
      if (this.file) {
        const formData = new FormData();
        formData.append("file", this.file);
        await this.$store
          .dispatch("customers/importCsv", formData)
          .then((res) => {
            this.loadItems();
            this.$refs.fileInput.value = "";
            this.file = null;
          });
      } else {
        NotificationService.showError("Please upload the csv file");
      }
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ pageLength: params.pageLength });
      this.loadItems();
    },

    onSortChange(params) {
      this.form.sortOrder = params[0].type;
      if (params[0].type == "none") this.form.sortOrder = "asc";
      this.form.sortBy = params[0].field;
      this.loadItems();
    },
    downloadCsv() {
      // Define CSV headers and a dummy row
      const headers = [
        "company_name",
        "vat_id",
        "address_line_1",
        "address_line_2",
        "city",
        "country",
        "zip_code",
        "phone",
        "credits",
        "invoice_email",
        "warning_email_address",
        "apply_reverse_charge",
        "external_order_number",
      ];

      const dummyRow = [
        "dummy name",
        "823594",
        "Address 1",
        "Address 2",
        "dummy city",
        "dummy country",
        "56234",
        "+84235324",
        "0.00",
        "dummy@gmail.com",
        "dummy address",
        "false",
        "R34354",
      ];

      // Create a CSV string
      const csvContent = [headers, dummyRow].map((e) => e.join(",")).join("\n");

      // Create a Blob from the CSV string
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);

      // Create a link element and trigger a download
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "companies.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url); // Clean up the URL object
    },
    // load items is what brings back the rows from server
    async loadItems() {
      try {
        this.$store.commit("showLoader", true);
        let response = await this.$store.dispatch("customers/list", {
          page: this.page,
          ...this.form,
          perPage: this.pageLength,
        });
        this.rows = response?.data?.data;
        this.totalRecords = response?.data?.total;
      } catch (e) {
        console.error("api error ___", e);
      } finally {
        this.$store.commit("showLoader", false);
      }
    },
    async destroy(id) {
      this.$swal({
        title: this.$t("Do you want to delete this record?"),
        text: this.$t("You can't revert your action"),
        type: "warning",
        customClass: "custom-delete-popup",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes delete it!"),
        cancelButtonText: this.$t("No"),
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed === true) {
          await this.$store.dispatch("customers/destroy", id).finally(() => {
            this.loadItems();
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
